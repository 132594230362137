<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  uniqueId: {
    type: String,
  },
  type: {
    type: String as PropType<'text' | 'email' | 'tel' | 'password' | 'url' | 'search'>,
    default: 'text',
  },
  placeHolder: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
    required: true,
  },
  required: {
    type: Boolean,
  },
  hasLabel: {
    type: Boolean,
    default: true,
  },
  iconPosition: {
    type: String as PropType<'icon-left' | 'icon-right'>,
    default: 'icon-left',
  }
})

const container: Ref<HTMLElement | null> = ref(null)

const emit = defineEmits(['update:modelValue'])
const slots = useSlots()
const computedLabel = ref(props.placeHolder)
const isInputFocus = ref(false)


const toggleChange = (event) => {
  container.value.classList.toggle('label', event.target?.value && props.hasLabel && 0 === Object.keys(slots).length)
  emit('update:modelValue', event.target?.value)
}

const selectInput = (isActive) => {
  isInputFocus.value = isActive
}


onMounted(() => {
  if (props.required) {
    computedLabel.value = computedLabel.value + '*'
  }
})
</script>

<template>
  <div
    ref="container"
    class="form-input"
    :class="{
      icon: Object.keys($slots).length !== 0,
      'icon-left': 'icon-left' === iconPosition && Object.keys($slots).length !== 0,
      'icon-right': 'icon-right' === iconPosition && Object.keys($slots).length !== 0,
      'label-focus': 0 !== props.modelValue.length || isInputFocus,
    }"
  >
    <slot />
    <label
      :for="uniqueId ?? name"
    >
      {{ computedLabel }}
    </label>
    <input
      @focusin="selectInput(true)"
      @focusout="selectInput(false)"
      :id="uniqueId ?? name"
      :type="type"
      :value="modelValue"
      :name="name"
      :required="required"
      @input="toggleChange"
    >
  </div>
</template>

<style scoped lang="scss">
.form-input {
  position: relative;
  border: 1px solid var(--color-navy-150);

  &.icon {
    :deep(svg) {
      font-size: 1.5em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.icon-left {
    label {
      left: 3.29rem;
    }

    input {
      padding:1.2rem 1rem 0.8rem 3.29rem;
    }

    :deep(svg) {
      left: 16px;
    }
  }

  &.icon-right {
    input {
      padding: 1.2rem 3.29rem 0.8rem 1rem;
    }

    :deep(svg) {
      right: 16px;
    }
  }

  &.label-focus, input:focus, input:active{
    label {
      top: 0.2rem;
      scale: .8;
      transform-origin: left top;
    }
  }

  label {
    position: absolute;
    top: 1rem;
    left: 1rem;
    text-transform: uppercase;
    transition: .2s cubic-bezier(0,0,.2,1) 0ms;
  }

  input {
    width: 100%;
    font-size: 1rem;
    color: var(--color-grey-550);
    border: none;
    padding: 1.2rem 1rem 0.8rem;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--color-grey-550);
      text-transform: uppercase;
      opacity: 1;
    }
  }
}
</style>
